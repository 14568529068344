'use client';

import { ReactNode, useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import { useMediaQuery } from '@mui/material';

import { useWindowWidth } from '@app/utils/style-utils';

const IntercomProvider = ({ children }: { children: ReactNode }) => {
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const windowWidth = useWindowWidth();

  useEffect(() => {
    const intercomApiKey = process.env.NEXT_PUBLIC_INTERCOM_API_KEY ?? '';

    Intercom({
      app_id: intercomApiKey,
      hide_default_launcher: false,
      custom_launcher_selector: '#intercom_launcher',
      alignment: 'right',
      vertical_padding: desktop ? 0 : 108,
      horizontal_padding: desktop ? 0 : windowWidth / 2 - 200,
    });
  }, [desktop, windowWidth]);

  return <>{children}</>;
};

export default IntercomProvider;
