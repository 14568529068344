'use client';

import { ReactNode } from 'react';
import {
  IntlProvider as ReactIntlProvider,
  MessageFormatElement,
} from 'react-intl';
import legacyEn from '@translations/customer-app/en.json';
import legacyFr from '@translations/customer-app/fr.json';
import { v4 as uuidV4 } from 'uuid';

import enTranslations from '@assets/compiled-lang/en.json';
import frTranslations from '@assets/compiled-lang/fr.json';

interface Props {
  children: ReactNode;
  locale: string;
}

const messages: Record<string, Record<string, MessageFormatElement[]>> = {
  fr: { ...frTranslations, ...legacyFr },
  en: { ...enTranslations, ...legacyEn },
};

const defaultRichTextElements = {
  // Added key to suppress warning https://github.com/formatjs/formatjs/issues/4782
  b: (...chunks: ReactNode[]) => <strong key={uuidV4()}>{chunks}</strong>,
  br: () => <span key={uuidV4()}>{'\n'}</span>,
};

const IntlProvider = ({ children, locale }: Props) => {
  return (
    <ReactIntlProvider
      locale={locale}
      messages={messages[locale]}
      defaultRichTextElements={defaultRichTextElements}
    >
      {children}
    </ReactIntlProvider>
  );
};

export default IntlProvider;
