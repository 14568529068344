'use client';
import { datadogRum } from '@datadog/browser-rum';

if (process.env.NEXT_PUBLIC_DATADOG_APP_ID) {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? '',
    site: 'datadoghq.eu',
    service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
    env: process.env.NEXT_PUBLIC_DATADOG_ENVIRONMENT ?? 'dev',
    version: process.env.HEROKU_SLUG_COMMIT ?? '0.0.0',
    sessionSampleRate: 20,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      {
        match: 'foodles.co',
        propagatorTypes: ['tracecontext', 'datadog'],
      },
    ],
  });
}
